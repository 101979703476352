import React from "react";

export default () => (
  <div className="message-container">
    <div className="avatar"></div>
    <div className="message-list">
      <p className="nickname">X11 color names</p>
      <p>Sorry, It's desktop only now</p>
      <p>
        you can visit <b>x11.linci.co</b>
      </p>
      <p>when your need a color with an easier decision</p>
    </div>
  </div>
);
