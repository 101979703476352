import React from "react";

export default () => (
  <footer>
    <p className="how">
      How to use: 1. Click the color dot to feel it, 2. Click the color name /
      hex to copy it.
    </p>
    <p className="copyright">
      <span>X11 color names</span> (
      <a
        href="https://en.wikipedia.org/wiki/X11_color_names"
        target="_blank"
        rel="noopener noreferrer"
      >
        What's this?
      </a>
      ) |{" "}
      <a
        href="https://www.w3.org/TR/2018/REC-css-color-3-20180619/#svg-color"
        target="_blank"
        rel="noopener noreferrer"
      >
        W3C
      </a>{" "}
      | Created by <a href="https://linci.co">Linci</a>
    </p>
  </footer>
);
