import React, { useState } from "react";
import "./clear.css";
import "./styles.scss";

import x11 from "./content/x11.json";
import Message from "./Message";
import Footer from "./Footer";

const Color = ({ data }) => {
  return (
    <div className="color">
      <div
        className="ball"
        style={{ backgroundColor: data.name }}
        onClick={() => {
          document.body.style.transition = "background-color 600ms";
          document.body.style.backgroundColor = data.name;
          setTimeout(() => {
            document.body.style.backgroundColor = null;
          }, 1500);
        }}
      />
      <div>
        <div
          className="name"
          title="Copy to clipboard"
          onClick={() => {
            navigator.clipboard.writeText(data.name);
          }}
        >
          {data.name}
        </div>
        <div className="caption nameCN">{data.cnName}</div>
        <div
          className="caption hex"
          onClick={() => {
            navigator.clipboard.writeText(data.hex.replace(/\s/g, ""));
          }}
        >
          <span className="label">HEX</span>
          <code>{data.hex}</code>
        </div>
      </div>
    </div>
  );
};

export default function App() {
  const [activeGroup, setActiveGroup] = useState("Pink");

  return (
    <>
      <Message />

      <div className="App">
        <div className="selector">
          {x11.map(group => (
            <div key={group.groupName}>
              <div
                className={
                  "item " + (activeGroup === group.groupName ? "active" : null)
                }
                onMouseEnter={() => setActiveGroup(group.groupName)}
              >
                <div
                  className="ball"
                  style={{ backgroundColor: group.groupName }}
                />
              </div>
            </div>
          ))}
        </div>

        <header>{activeGroup}</header>

        <div className="color-grid">
          {x11.map(
            group =>
              group.groupName === activeGroup &&
              group.colors.map(color => <Color key={color.name} data={color} />)
          )}
        </div>

        <Footer />
      </div>
    </>
  );
}
